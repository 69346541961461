
































import { Vue, Component } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogProperties";
import RouteUtil from "@/utilities/RouteUtil";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import FacebookService from "@/services/FacebookService";
import CompanyService from "@/services/CompanyService";
import store from "@/main-store";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");

@Component({
    components: { AlertDialogView },
    data: () => ({
        title: ""
    })
})
export default class AuthenticatingView extends Vue {
    private alertDialog = new AlertDialogProperties();
    private facebookService = new FacebookService();
    private companyService = new CompanyService();

    public async created() {
        await this.loadCompany();

        const query = this.$route.query;

        if (query.type && query.code) {
            var redirectUrl = RouteUtil.getUrlWithoutPathname(true);
            redirectUrl += "authenticating?type=" + query.type;
            if (query.from) {
                redirectUrl += "&from=" + query.from;
            }
            // if (query.h) {
            //     redirectUrl += "&h=" + query.h;
            // }

            if (query.type === "login") {
                await this.login(redirectUrl, <any>query.code);
            } else if (query.type === "link") {
                await this.link(redirectUrl, <any>query.code);
            } else if (query.type === "refresh") {
                await this.refresh(redirectUrl, <any>query.code);
            } else {
                await this.$router.push("/");
            }
        } else {
            await this.$router.push("/");
        }
    }

    public async loadCompany() {
        try {
            const r = await this.companyService.summary();
            document.title = r.data.name;
            this.$data.title = document.title;
        } catch (e) {
            document.title = this.$t('title.app').toString();
            this.$data.title = document.title;
        }
    }

    public async refresh(redirectUrl: string, code: string) {
        try {
            await this.facebookService.refreshToken(redirectUrl, code);
            var user = await store.reloadUser();
            if (user) {
                this.back();
            } else {
                throw new Error(this.$t("message.refresh-failed").toString());
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "refresh-failed" };
                this.alertDialog.visible = true;
            }
        }
    }

    public async login(redirectUrl: string, code: string) {
        try {
            await this.facebookService.login(redirectUrl, code);
            var user = await store.reloadUser();
            if (user) {
                await this.$router.push("/home");
            } else {
                throw new Error(this.$t("message.login-failed").toString());
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "login-failed" };
                this.alertDialog.visible = true;
            }
        }
    }

    public async link(redirectUrl: string, code: string) {
        try {
            await this.facebookService.link(redirectUrl, code);
            var user = await store.reloadUser();
            if (user) {
                await this.back();
            } else {
                throw new Error(this.$t("message.link-failed").toString());
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "link-failed" };
                this.alertDialog.visible = true;
            }
        }
    }

    public async back() {
        const query = this.$route.query;
        if (query.from === "profile") {
            var url = "/profile";
            if (query.type === 'link') url += '#main';
            await this.$router.push(url);
        } else if (query.from === 'system-setting') {
            var url = "/management/settings/system-setting";
            await this.$router.push(url);
        } else if (query.from === 'live-session') {
            var url = "/management/live-sessions";
            await this.$router.push(url);
        } else {
            await this.$router.push("/home");
        }
    }

    public async alertDialogClicked(button: string) {
        this.alertDialog.visible = false;
        this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag) {
            const command = tag.command;
            if (command === "login-failed") {
                await this.$router.push("/");
            } else if (command === 'link-failed' || command === 'refresh-failed') {
                await this.back();
            }
        }
    }
}
